import * as d3 from 'd3';
import { legend, unique } from '../helpers';

export default function tree(entidade, filtro, data, chartConfig) {
    var chart = d3.select('.chart-content'),
        width = chart.attr('width'),
        height = chart.attr('height');

    const treeData = {
        key: 'Classificação OCDE',
        values: d3.nest()
            .key(d => d.ocde_area_geral)
            .key(d => d.ocde_area_especifica)
            .key(d => d.ocde_area_detalhada)
            .key(d => d.ocde_curso)
            .key(d => d.nome)
            .entries(data)
    };

    /* const dx = 10;
    const dy = width / 4;
    const tree = d3.tree().size([width, height]);
    const diagonal = d3.linkHorizontal().x(d => d.y).y(d => d.x)

    const root = d3.hierarchy(treeData, d => d.values);

    root.x0 = dy / 2;
    root.y0 = 0;
    root.descendants().forEach((d, i) => {
        if(d.depth !== 5) {
            d.id = i;
            d._children = d.children;
        } else {
            d.children = null;
        }
    });

    const gLink = chart.append('g')
        .attr('class', 'links')
        .attr('fill', 'none')
        .attr('stroke', '#555')
        .attr('stroke-opacity', 0.4)
        .attr('stroke-width', 1.5);

    const gNode = chart.append('g')
        .attr('class', 'nodes')
        .attr('cursor', 'pointer')
        .attr('pointer-events', 'all');

    function update(source) {
        const duration = d3.event && d3.event.altKey ? 2500 : 250;
        const nodes = root.descendants().reverse();
        const links = root.links();

        // Compute the new tree layout.
        tree(root);

        let left = root;
        let right = root;
        root.eachBefore(node => {
            if(node.x < left.x) {
                left = node;
            }
            if(node.x > right.x) {
                right = node;
            }
        });

        const transition = chart.transition()
            .duration(duration)
            .tween('resize', window.ResizeObserver ? null : () => () => chart.dispatch('toggle'));

        // Update the nodes…
        const node = gNode.selectAll('g')
            .data(nodes, d => d.id);

        // Enter any new nodes at the parent's previous position.
        const nodeEnter = node.enter().append('g')
            .attr('transform', d => `translate(${source.y0},${source.x0})`)
            .attr('fill-opacity', 0)
            .attr('stroke-opacity', 0)
            .on('click', d => {
                d.children = d.children ? null : d._children;
                update(d);
            });

        nodeEnter.append('circle')
            .attr('r', 2.5)
            .attr('fill', d => d._children ? '#555' : '#999')
            .attr('stroke-width', 10);

        nodeEnter.append('text')
            .attr('dy', '0.31em')
            .attr('x', d => d._children ? -6 : 6)
            .attr('text-anchor', d => d._children ? 'end' : 'start')
            .text(d => d.data.key)
            .clone(true).lower()
            .attr('stroke-linejoin', 'round')
            .attr('stroke-width', 3)
            .attr('stroke', 'white');

        // Transition nodes to their new position.
        node.merge(nodeEnter).transition(transition)
            .attr('transform', d => `translate(${d.y},${d.x})`)
            .attr('fill-opacity', 1)
            .attr('stroke-opacity', 1);

        // Transition exiting nodes to the parent's new position.
        node.exit().transition(transition).remove()
            .attr('transform', d => `translate(${source.y},${source.x})`)
            .attr('fill-opacity', 0)
            .attr('stroke-opacity', 0);

        // Update the links…
        const link = gLink.selectAll('path')
            .data(links, d => d.target.id);

        // Enter any new links at the parent's previous position.
        const linkEnter = link.enter().append('path')
            .attr('d', d => {
                const o = { x: source.x0, y: source.y0 };
                return diagonal({ source: o, target: o });
            });

        // Transition links to their new position.
        link.merge(linkEnter).transition(transition)
            .attr('d', diagonal);

        // Transition exiting nodes to the parent's new position.
        link.exit().transition(transition).remove()
            .attr('d', d => {
                const o = { x: source.x, y: source.y };
                return diagonal({ source: o, target: o });
            });

        // Stash the old positions for transition.
        root.eachBefore(d => {
            d.x0 = d.x;
            d.y0 = d.y;
        });
    }

    update(root); */

    const root = d3.hierarchy(treeData, d => d.values);
    root.descendants().forEach((d, i) => {
        if(d.depth !== 5) {
            d.id = i;
            d._children = d.children;
        } else {
            d.children = null;
        }
    });
    d3.tree().size([height, width])(root);

    // Links
    chart
        .append('g')
        .attr('class', 'links')
        .selectAll('line.link')
        .data(root.links())
        .enter()
        .append('line')
        .attr('class', 'link')
        .attr('x1', d => d.source.y)
        .attr('y1', d => d.source.x)
        .attr('x2', d => d.target.y)
        .attr('y2', d => d.target.x);

    // Nodes
    chart
        .append('g')
        .attr('class', 'nodes')
        .selectAll('circle.node')
        .data(root.descendants())
        .enter()
        .append('circle')
        .attr('class', 'node')
        .attr('cx', d => d.y)
        .attr('cy', d => d.x)
        .attr('r', 4);

    // Labels
    chart
        .append('g')
        .attr('class', 'labels')
        .selectAll('text.label')
        .data(root.descendants())
        .enter()
        .append('text')
        .text((d) => d.data.key)
        .attr('class', 'label')
        .attr('x', d => d.y)
        .attr('y', d => d.x);

    /* if(chartConfig.grouped) {
        var x = d3.scaleBand()
            .rangeRound([0, width])
            .domain(data.map(d => d.ano).filter(unique))
            .paddingInner(0.1);

        var x1 = d3.scaleBand()
            .rangeRound([0, x.bandwidth()])
            .domain(data.map(d => d[filtro]).filter(unique))
            .padding(0.05);

        var y = d3.scaleLinear()
            .rangeRound([height, 0])
            .domain([0, d3.max(data, d => +d[entidade])]).nice();

        var colorScale = d3.scaleOrdinal(d3.schemeCategory10);
    } else {
        var x = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.1)
            .domain(data.map(d => d[filtro]).filter(unique));

        var y = d3.scaleLinear()
            .rangeRound([height, 0])
            .domain([0, d3.max(data, d => +d[entidade])]).nice();
    }

    // Draw y axis grid
    chart.append('g')
        .attr('class', 'grid')
        .call(d3.axisLeft(y)
            .tickSize(-width)
            .tickFormat(''));

    // Draw X axis
    chart.append('g')
        .attr('class', 'axis x')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x));

    // Draw Y axis
    chart.append('g')
        .attr('class', 'axis y')
        .call(d3.axisLeft(y).ticks(null, 's'));

    if(chartConfig.grouped) {
        var groupedData = d3.nest().key(d => d.ano).entries(data);

        // Draw bars
        chart.append('g')
            .attr('class', 'bars')
            .selectAll('g')
            .data(groupedData)
            .enter().append('g')
            .attr('transform', d => 'translate(' + x(d.key) + ',0)')
            .selectAll('rect')
            .data(d => d.values)
            .enter().append('rect')
            .attr('x', d => x1(d[filtro]))
            .attr('y', d => y(d[entidade]))
            .attr('width', x1.bandwidth())
            .attr('height', d => height - y(d[entidade]))
            .attr('fill', d => colorScale(d[filtro]));

        // Draw text labels
        chart.append('g')
            .attr('class', 'labels')
            .selectAll('text')
            .data(data)
            .enter().append('text')
            .text(d => d[entidade])
            .attr('class', d => 'label ' + (height - y(d[entidade]) < 20 ? 'out' : 'in'))
            .attr('x', d => x(d.ano) + (x1(d[filtro]) + x1.bandwidth() / 2))
            .attr('y', d => height - y(d[entidade]) < 20 ? y(d[entidade]) - 5 : y(d[entidade]) + 15);
    } else {
        // Draw bars
        chart.append('g')
            .attr('class', 'bars')
            .selectAll('rect')
            .data(data)
            .enter().append('rect')
            .attr('x', d => x(d[filtro]))
            .attr('y', d => y(d[entidade]))
            .attr('width', x.bandwidth())
            .attr('height', d => height - y(d[entidade]))
            .attr('fill', 'steelblue');

        // Draw text labels
        chart.append('g')
            .attr('class', 'labels')
            .selectAll('text')
            .data(data)
            .enter().append('text')
            .text(d => d[entidade])
            .attr('class', d => 'label ' + (height - y(d[entidade]) < 20 ? 'out' : 'in'))
            .attr('x', d => x(d[filtro]) + x.bandwidth() / 2)
            .attr('y', d => height - y(d[entidade]) < 20 ? y(d[entidade]) - 5 : y(d[entidade]) + 15);
    }

    // Fill legends
    if(chartConfig.legend !== false) {
        legend(d3.values(data.map(d => d[filtro])).filter(unique), colorScale);
    } */
}