import * as d3 from 'd3';
import { legend, unique, capitalize } from '../helpers';

export default function line(entidade, filtro, data, chartConfig, chart, width, height) {
    var chart = d3.select('.chart-content'),
        width = +chart.attr('width'),
        height = +chart.attr('height');

    if(chartConfig.grouped) {
        var x = d3.scalePoint()
            .range([0, width])
            .domain(data.map(d => d.ano).filter(unique));

        var y = d3.scaleLinear()
            .rangeRound([height, 0])
            .domain([0, d3.max(data, d => +d[entidade])]).nice();

        var colorScale = d3.scaleOrdinal(d3.schemeCategory10);

        var line = d3.line()
            .defined(d => !isNaN(d[entidade]))
            .x(d => x(d.ano))
            .y(d => y(d[entidade]));
    } else {
        var x = d3.scalePoint()
            .range([0, width])
            .domain(data.map(d => d[filtro]));

        var y = d3.scaleLinear()
            .rangeRound([height, 0])
            .domain([0, d3.max(data, d => +d[entidade])]).nice();

        var line = d3.line()
            .defined(d => !isNaN(d[entidade]))
            .x(d => x(d[filtro]))
            .y(d => y(d[entidade]));
    }

    // Draw X axis grid
    chart.append('g')
        .attr('class', 'grid')
        .call(d3.axisBottom(x)
            .tickSize(height)
            .tickFormat(''));

    // Draw Y axis grid
    chart.append('g')
        .attr('class', 'grid')
        .call(d3.axisLeft(y)
            .tickSize(-width)
            .tickFormat(''));

    // Draw X axis
    chart.append('g')
        .attr('class', 'axis x')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x));

    // Draw Y axis
    chart.append('g')
        .attr('class', 'axis y')
        .call(d3.axisLeft(y).ticks(null, 's'));

    // Insert Y axis name
    chart.append('text')
        .attr('class', 'axis-label')
        .attr('transform', 'rotate(270)')
        .attr('x', -(height / 2))
        .attr('y', -30)
        .text(capitalize(entidade));

    // Insert X axis name
    if (chartConfig.legend === false) {
        chart.append('text')
            .attr('class', 'axis-label')
            .attr('x', width / 2)
            .attr('y', height + 30)
            .text(capitalize('Anos'));
    }

    // Draw chart
    var viz = chart.append('g')
        .attr('fill', 'none')
        .attr('stroke-width', 2)
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round');

    if(chartConfig.grouped) {
        var nestedData = d3.nest().key(d => d[filtro]).entries(data);

        // Draw line
        viz.selectAll('path')
            .data(nestedData)
            .enter().append('path')
            .attr('stroke', d => colorScale(d.key))
            .style('mix-blend-mode', 'multiply')
            .attr('d', d => line(d.values.map(v => { v.key = d.key; return v; })));

        // Draw points
        var div = d3.select('body').append('div')
            .attr('class', 'tooltip')
            .style('opacity', 0);
        viz.selectAll('circle')
            .data(data)
            .enter().append('circle')
            .attr('fill', d => colorScale(d.key))
            .attr('r', 3)
            .attr('cx', d => x(d.ano))
            .attr('cy', d => y(d[entidade]))
            .on('mouseover', function(d) {
                div.transition()
                    .duration(200)
                    .style('opacity', .9);
                div.html(d[entidade])
                    .style('left', (d3.event.pageX - 25) + 'px')
                    .style('top', (d3.event.pageY - 20) + 'px')
                    .style('color', colorScale(d.key))
                    .style('border', '1px solid ' + colorScale(d.key));
            })
            .on('mouseout', function(d) {
                div.transition()
                    .duration(500)
                    .style('opacity', 0);
            });
    } else {
        // Draw line
        viz.append('path')
            .datum(data)
            .attr('stroke', 'steelblue')
            .attr('d', line);

        // Draw points
        viz.selectAll('circle')
            .data(data)
            .enter().append('circle')
            .attr('fill', 'steelblue')
            .attr('r', 3)
            .attr('cx', d => x(d[filtro]))
            .attr('cy', d => y(d[entidade]));

        // Draw labels
        viz.selectAll('text')
            .data(data)
            .enter().append('text')
            .text(d => d[entidade])
            .attr('class', 'label out')
            .attr('x', (d, i) => {
                var pos = x(d[filtro]);
                if(i == 0) {
                    return pos + 15
                }
                if(i == data.length - 1) {
                    return pos - 12;
                }
                return pos;
            })
            .attr('y', (d, i) => {
                var pos = y(d[entidade]);
                if(i == 0) {
                    return pos + 10;
                }
                if(pos < 10) {
                    return pos + 15;
                }
                return pos - 5;
            });
    }

    // Fill legends
    if(chartConfig.legend !== false) {
        legend(d3.values(data.map(d => d[filtro])).filter(unique), colorScale);
    }
}