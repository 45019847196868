function tooltip(data, entidade, filtro) {
    return d => {
        if(typeof (d.ano) !== 'string') {
            return '';
        }
        var currentYearValues = data.filter(item => item.ano == d.ano);
        var allKeys = data.map(item => item[filtro]).filter(unique);

        var total = d3.sum(currentYearValues, d => +d[entidade]);

        var table = '<table class="tooltip-table">';
        allKeys.forEach(currentKey => {
            var current = currentYearValues.find(value => value[filtro] == currentKey);
            var currentValue = current ? current[entidade] : 0;
            table += '<tr><td class="title"' + (currentKey == d[filtro] ? ' current' : '') + '>';
            table += currentKey;
            table += ':</td>';
            table += '<td class="data"' + (currentKey == d[filtro] ? ' current' : '') + '>';
            table += currentValue;
            table += ' (' + ((currentValue / total) * 100).toFixed(2) + '%)';
            table += '</td></tr>';
        });
        table += '<tr><td class="title total">Total:</td>';
        table += '<td class="data total">' + total + '</td></tr>';
        table += '</table>';

        return table;
    }
}