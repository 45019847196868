import * as d3 from 'd3';
import { legend, unique } from '../helpers';

export default function stackedBar(entidade, filtro, data, chartConfig) {
    var chart = d3.select('.chart-content'),
        width = chart.attr('width'),
        height = chart.attr('height');

    var stackedData = d3.nest()
        .key(d => d.ano)
        .entries(data)
        .map(d => d.values
            .reduce((acc, cur) => {
                acc[cur[filtro]] = +cur[entidade];
                return acc;
            }, { ano: d.key })
        );

    var stack = d3.stack()
        .keys(data
            .map(d => d[filtro])
            .filter(unique)
        )
        .order(d3.stackOrderDescending);

    var x = d3.scaleBand()
        .rangeRound([0, width])
        .paddingInner(0.1);

    var y = d3.scaleLinear()
        .rangeRound([height, 0]);

    var colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    x.domain(data.map(d => d.ano).filter(unique));
    y.domain([0, d3.max(stackedData, d => d3.sum(Object.values(d).slice(1)))]).nice();

    // Draw y axis grid
    chart.append('g')
        .attr('class', 'grid')
        .call(d3.axisLeft(y)
            .tickSize(-width)
            .tickFormat(''));

    // Draw X axis
    chart.append('g')
        .attr('class', 'axis x')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x));

    // Draw Y axis
    chart.append('g')
        .attr('class', 'axis y')
        .call(d3.axisLeft(y).ticks(null, 's'));

    // Draw bars
    chart.append('g')
        .selectAll('g')
        .data(stack(stackedData))
        .enter().append('g')
        .attr('fill', d => colorScale(d.key))
        .selectAll('rect')
        .data(d => d)
        .enter().append('rect')
        .attr('x', d => x(d.data.ano))
        .attr('y', d => y(d[1]))
        .attr('width', x.bandwidth())
        .attr('height', d => y(d[0]) - y(d[1]));

    // Fill legends
    if(chartConfig.legend !== false) {
        legend(d3.values(data.map(d => d[filtro])).filter(unique), colorScale);
    }
}