import * as d3 from 'd3';
import { legend, unique, capitalize } from '../helpers';

export default function bar(entidade, filtro, data, chartConfig) {
    var chart = d3.select('.chart-content'),
        width = chart.attr('width'),
        height = chart.attr('height');

    if(chartConfig.grouped) {
        var x = d3.scaleBand()
            .rangeRound([0, width])
            .domain(data.map(d => d.ano).filter(unique))
            .paddingInner(0.1);

        var x1 = d3.scaleBand()
            .rangeRound([0, x.bandwidth()])
            .domain(data.map(d => d[filtro]).filter(unique))
            .padding(0.05);

        var y = d3.scaleLinear()
            .rangeRound([height, 0])
            .domain([0, d3.max(data, d => +d[entidade])]).nice();

        var colorScale = d3.scaleOrdinal(chartConfig.colorScale || d3.schemeCategory10);

        var groupedData = d3.nest()
            .key(d => d.ano)
            .sortValues((a, b) => d3.ascending(a[entidade, b[entidade]]))
            .entries(data);
    } else {
        var x = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.1)
            .domain(data.map(d => d[filtro]).filter(unique));

        var y = d3.scaleLinear()
            .rangeRound([height, 0])
            .domain([0, d3.max(data, d => +d[entidade])]).nice();
    }

    // Draw x axis grid
    if(chartConfig.xGrid) {
        chart.append('g')
            .attr('class', 'grid')
            .selectAll('rect')
            .data(groupedData)
            .enter().append('rect')
            .style('fill', '#f1f1f1')
            .attr('x', d => x(d.key))
            .attr('y', 0)
            .attr('width', x.bandwidth())
            .attr('height', height);
    }


    // Draw y axis grid
    chart.append('g')
        .attr('class', 'grid')
        .call(d3.axisLeft(y)
            .tickSize(-width)
            .tickFormat(''));

    // Draw X axis
    chart.append('g')
        .attr('class', 'axis x')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x));

    // Draw Y axis
    chart.append('g')
        .attr('class', 'axis y')
        .call(d3.axisLeft(y).ticks(null, 's'));

    // Insert Y axis name
    chart.append('text')
        .attr('class', 'axis-label')
        .attr('transform', 'rotate(270)')
        .attr('x', -(height / 2))
        .attr('y', -30)
        .text(capitalize(entidade));

    if(chartConfig.grouped) {
        // Draw bars
        chart.append('g')
            .attr('class', 'bars')
            .selectAll('g')
            .data(groupedData)
            .enter().append('g')
            .attr('transform', d => 'translate(' + x(d.key) + ',0)')
            .selectAll('rect')
            .data(d => d.values)
            .enter().append('rect')
            .attr('x', d => x1(d[filtro]))
            .attr('y', d => y(d[entidade]))
            .attr('width', x1.bandwidth())
            .attr('height', d => height - y(d[entidade]))
            .attr('fill', d => colorScale(d[filtro]));

        // Draw text labels
        if(chartConfig.showLabels !== false) {
            chart.append('g')
                .attr('class', 'labels')
                .selectAll('text')
                .data(data)
                .enter().append('text')
                .text(d => d[entidade])
                .attr('class', d => 'label ' + (height - y(d[entidade]) < 20 ? 'out' : 'in'))
                .attr('x', d => x(d.ano) + (x1(d[filtro]) + x1.bandwidth() / 2))
                .attr('y', d => height - y(d[entidade]) < 20 ? y(d[entidade]) - 5 : y(d[entidade]) + 15);
        }
    } else {
        // Draw bars
        chart.append('g')
            .attr('class', 'bars')
            .selectAll('rect')
            .data(data)
            .enter().append('rect')
            .attr('x', d => x(d[filtro]))
            .attr('y', d => y(d[entidade]))
            .attr('width', x.bandwidth())
            .attr('height', d => height - y(d[entidade]))
            .attr('fill', 'steelblue');

        // Draw text labels
        if(chartConfig.showLabels !== false) {
            chart.append('g')
                .attr('class', 'labels')
                .selectAll('text')
                .data(data)
                .enter().append('text')
                .text(d => d[entidade])
                .attr('class', d => 'label ' + (height - y(d[entidade]) < 20 ? 'out' : 'in'))
                .attr('x', d => x(d[filtro]) + x.bandwidth() / 2)
                .attr('y', d => height - y(d[entidade]) < 20 ? y(d[entidade]) - 5 : y(d[entidade]) + 15);
        }
    }

    // Fill legends
    if(chartConfig.legend !== false) {
        legend(d3.values(data.map(d => d[filtro])).filter(unique), colorScale);
    }
}