import * as d3 from 'd3';
import { legend, unique } from '../helpers';

export default function treemap(entidade, filtro, data, chartConfig) {
    var chart = d3.select('.chart-content'),
        width = chart.attr('width'),
        height = chart.attr('height');

    const treeData = {
        key: 'Classificação OCDE',
        values: d3.nest()
            .key(d => d.ocde_area_geral)
            .key(d => d.ocde_area_especifica)
            .key(d => d.ocde_area_detalhada)
            .key(d => d.ocde_curso)
            .key(d => d.nome)
            .entries(data)
    };

    const root = d3.hierarchy(treeData, d => d.values);
    root.descendants().forEach((d, i) => {
        if(d.depth < 5) {
            d.id = i;
            d._children = d.children;
        } else {
            d.children = null;
        }
    });
    d3.tree().size([height, width])(root);
}