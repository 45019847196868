import * as d3 from 'd3';

export default {
    ano: {
        visualizations: ['line'],
        legend: false
    },
    curso: {
        visualizations: ['stacked-area'],
        legend: false
    },
    campus: {
        visualizations: ['bar'],
        grouped: true,
        colorScale: d3.schemeDark2
    },
    sexo: {
        text: 'gênero',
        map: item => {
            item.sexo = { M: 'Masculino', F: 'Feminino' }[item.sexo];
            return item;
        },
        visualizations: ['bar'],
        grouped: true
    },
    cor_raca: {
        text: 'cor/raça',
        visualizations: ['line'],
        grouped: true
    },
    idade: {
        visualizations: ['bar-distribution'],
        legend: false
    }
};
