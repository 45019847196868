import * as d3 from 'd3';
import { capitalize } from './index.js'

export default function legend(data, colorScale) {
    var legendItems = d3.select('.legend')
            .append('xhtml:div')
            .attr('xmlns', 'http://www.w3.org/1999/xhtml')
            .selectAll('span')
            .data(data)
            .enter().append('span')
            .attr('class', 'legend-item')
            .style('color', colorScale);

        legendItems.append('span')
            .attr('class', 'legend-color')
            .style('background-color', colorScale);

        legendItems.append('span')
            .attr('class', 'legend-text')
            .text(d => capitalize(d));
}