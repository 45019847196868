import * as d3 from 'd3';
import { legend, unique } from '../helpers';

export default function bar(entidade, filtro, data, chartConfig) {
    var chart = d3.select('.chart-content'),
        width = chart.attr('width'),
        height = chart.attr('height');

    if(chartConfig.grouped) {
        var y = d3.scaleBand()
            .rangeRound([0, height])
            .domain(data.map(d => d.ano).filter(unique))
            .paddingInner(0.2);

        var y1 = d3.scaleBand()
            .rangeRound([0, y.bandwidth()])
            .domain(data.map(d => d[filtro]).filter(unique))
            .padding(0.05);

        var x = d3.scaleLinear()
            .rangeRound([0, width])
            .domain([0, d3.max(data, d => +d[entidade])]).nice();

        var colorScale = d3.scaleOrdinal(d3.schemeCategory10);
    } else {
        data = data.sort((a, b) => b[entidade] - a[entidade]);

        var y = d3.scaleBand()
            .rangeRound([0, height])
            .domain(data.map(d => d[filtro]).filter(unique))
            .paddingInner(0.2);

        var x = d3.scaleLinear()
            .rangeRound([0, width])
            .domain([0, d3.max(data, d => +d[entidade])]).nice();
    }

    // Draw X axis grid
    chart.append('g')
        .attr('class', 'grid')
        .call(d3.axisBottom(x)
            .tickSize(height)
            .tickFormat(''));

    // Draw X axis
    chart.append('g')
        .attr('class', 'axis x')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x));

    // Draw Y axis
    chart.append('g')
        .attr('class', 'axis y')
        .call(d3.axisLeft(y).tickSize(0));

    if(chartConfig.grouped) {
        var groupedData = d3.nest().key(d => d.ano).entries(data);

        // Draw bars
        chart.append('g')
            .attr('class', 'bars')
            .selectAll('g')
            .data(groupedData)
            .enter().append('g')
            .attr('transform', d => 'translate(0,' + y(d.key) + ')')
            .selectAll('rect')
            .data(d => d.values)
            .enter().append('rect')
            .attr('x', 1)
            .attr('y', d => y1(d[filtro]))
            .attr('height', y1.bandwidth())
            .attr('width', d => x(d[entidade]))
            .attr('fill', d => colorScale(d[filtro]));

        // Draw text labels
        /* chart.append('g')
            .attr('class', 'labels')
            .selectAll('text')
            .data(data)
            .enter().append('text')
            .text(d => d[entidade])
            .attr('class', d => 'label ' + (height - y(d[entidade]) < 20 ? 'out' : 'in'))
            .attr('x', d => x(d.ano) + (x1(d[filtro]) + x1.bandwidth() / 2))
            .attr('y', d => height - y(d[entidade]) < 20 ? y(d[entidade]) - 5 : y(d[entidade]) + 15); */
    } else {
        // Draw bars
        chart.append('g')
            .attr('class', 'bars')
            .selectAll('rect')
            .data(data)
            .enter().append('rect')
            .attr('x', 1)
            .attr('y', d => y(d[filtro]))
            .attr('height', y.bandwidth())
            .attr('width', d => x(d[entidade]))
            .attr('fill', 'steelblue');

        // Draw text labels
        chart.append('g')
            .attr('class', 'labels')
            .selectAll('text')
            .data(data)
            .enter().append('text')
            .text(d => d[entidade])
            .attr('class', 'label in')
            .attr('alignment-baseline', 'middle')
            .attr('x', d => x(d[entidade]) - 10)
            .attr('y', d => y(d[filtro]) + y.bandwidth() / 2);
    }

    // Fill legends
    if(chartConfig.legend !== false) {
        legend(d3.values(data.map(d => d[filtro])).filter(unique), colorScale);
    }
}