export default {
    ano: {
        visualizations: ['line'],
        legend: false
    },
    sexo: {
        text: 'gênero',
        map: item => {
            item.sexo = { M: 'Masculino', F: 'Feminino' }[item.sexo];
            return item;
        },
        visualizations: ['bar'],
        grouped: true
    },
    escolaridade: {
        visualizations: ['ranking'],
        grouped: true
    },
    idade: {
        visualizations: ['bubble'],
        legend: false
    },
    cor_raca: {
        text: 'cor/raça',
        visualizations: ['ranking'],
        grouped: true
    },
    /* nacionalidade: {
        map: item => {
            item.nacionalidade = item.pais;
            delete item.pais;
            return item;
        },
        visualizations: ['stacked-bar'],
        grouped: true
    }, */
    atuacao: {
        text: 'atuação',
        visualizations: ['bar'],
        grouped: true,
        showLabels: false,
        xGrid: true
    },
    situacao: {
        text: 'situação',
        visualizations: ['ranking'],
        grouped: true
    }/* ,
    regime: {
        visualizations: ['ranking'],
        grouped: true
    } */
};
