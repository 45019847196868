import * as d3 from 'd3';
import { legend, unique } from '../helpers';

export default function dynamicBar(entidade, filtro, data, chartConfig) {
    var chart = d3.select('.chart-content'),
        width = chart.attr('width'),
        height = chart.attr('height');

    filtro = 'campus';

    var dataByYear = d3.nest()
        .key(d => d.ano)
        .key(d => d.curso)
        .rollup(values => ({
            'vagas': d3.sum(values, d => d.vagas),
            'inscritos': d3.sum(values, d => d.inscritos),
            'matriculados': d3.sum(values, d => d.matriculados),
            'ingressantes': d3.sum(values, d => d.ingressantes),
            'concluintes': d3.sum(values, d => d.concluintes)
        }))
        .entries(data);

    var x = d3.scaleBand()
        .rangeRound([0, width])
        .domain(data.map(d => d.ano).filter(unique))
        .paddingInner(0.1);

    var x1 = d3.scaleBand()
        .rangeRound([0, x.bandwidth()])
        .domain(data.map(d => d[filtro]).filter(unique))
        .padding(0.05);

    var y = d3.scaleLinear()
        .rangeRound([height, 0])
        .domain([0, d3.max(data, d => +d[entidade])]).nice();

    var colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    // Draw y axis grid
    chart.append('g')
        .attr('class', 'grid')
        .call(d3.axisLeft(y)
            .tickSize(-width)
            .tickFormat(''));

    // Draw X axis
    chart.append('g')
        .attr('class', 'axis x')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x));

    // Draw Y axis
    chart.append('g')
        .attr('class', 'axis y')
        .call(d3.axisLeft(y).ticks(null, 's'));

    // Draw bars
    chart.append('g')
        .attr('class', 'bars')
        .selectAll('g')
        .data(dataByYear)
        .enter().append('g')
        .attr('transform', d => 'translate(' + x(d.key) + ',0)')
        .selectAll('rect')
        .data(d => d.values)
        .enter().append('rect')
        .attr('x', d => x1(d[filtro]))
        .attr('y', d => y(d[entidade]))
        .attr('width', x1.bandwidth())
        .attr('height', d => height - y(d[entidade]))
        .attr('fill', d => colorScale(d[filtro]));

    // Draw text labels
    chart.append('g')
        .attr('class', 'labels')
        .selectAll('text')
        .data(data)
        .enter().append('text')
        .text(d => d[entidade])
        .attr('class', d => 'label ' + (height - y(d[entidade]) < 20 ? 'out' : 'in'))
        .attr('x', d => x(d.ano) + (x1(d[filtro]) + x1.bandwidth() / 2))
        .attr('y', d => height - y(d[entidade]) < 20 ? y(d[entidade]) - 5 : y(d[entidade]) + 15);

    // Fill legends
    if(chartConfig.legend !== false) {
        legend(d3.values(data.map(d => d[filtro])).filter(unique), colorScale);
    }
}