import * as d3 from 'd3';
import { capitalize, legend, unique } from '../helpers';

export default function stackedArea(entidade, filtro, data, chartConfig, chart, width, height) {
    var chart = d3.select('.chart-content'),
        width = chart.attr('width'),
        height = chart.attr('height');

    var stackedData = d3.nest()
        .key(d => d.ano)
        .entries(data)
        .map(d => d.values
            .reduce((acc, cur) => {
                acc[cur[filtro]] = +cur[entidade];
                return acc;
            }, { ano: d.key })
        );

    var stack = d3.stack()
        .keys(data
            .map(d => d[filtro])
            .filter(unique)
        )
        .order(d3.stackOrderDescending);

    var x = d3.scalePoint()
        .range([0, width])
        .domain(data.map(d => d.ano).filter(unique));

    var y = d3.scaleLinear()
        .rangeRound([height, 0])
        .domain([0, d3.max(stackedData, d => d3.sum(Object.values(d).slice(1)))]).nice();

    var colorScale = d3.scaleOrdinal(d3.schemePaired);

    var area = d3.area()
        .defined(d => !isNaN(d[1]))
        .x(d => x(d.data.ano))
        .y0(d => y(d[0]))
        .y1(d => y(d[1]));

    var line = d3.line()
        .defined(d => !isNaN(d[1]))
        .x(d => x(d.data.ano))
        .y(d => y(d[1]));

    // Draw y axis grid
    chart.append('g')
        .attr('class', 'grid')
        .call(d3.axisLeft(y)
            .tickSize(-width)
            .tickFormat(''));

    // Draw X axis
    chart.append('g')
        .attr('class', 'axis x')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x));

    // Draw x axis grid
    chart.append('g')
        .attr('class', 'grid')
        .call(d3.axisBottom(x)
            .tickSize(height)
            .tickFormat(''));

    // Draw Y axis
    chart.append('g')
        .attr('class', 'axis y')
        .call(d3.axisLeft(y).ticks(null, 's'));

    // Create tooltip
    var div = d3.select('body').append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0);

    // Draw chart
    var viz = chart.append('g')
        .attr('fill', 'none')
        .attr('stroke-width', 1)
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round');

    // Draw area
    viz.selectAll('path.area')
        .data(stack(stackedData))
        .enter().append('path')
        .attr('class', 'area')
        .attr('fill', d => colorScale(d.key))
        .style('opacity', .8)
        .attr('d', area)
        .on('mouseover', d => {
            div.transition()
                .duration(200)
                .style('opacity', .9);
            div.html(capitalize(d.key))
                .style('left', (d3.event.pageX - 25) + 'px')
                .style('top', (d3.event.pageY - 20) + 'px')
                .style('color', 'darkslategray')
                .style('border', '1px solid ' + colorScale(d.key))
                .style('width', '250px');
        })
        .on('mouseout', d => {
            div.transition()
                .duration(500)
                .style('opacity', 0);
        });

    // Draw line
    viz.selectAll('path.line')
        .data(stack(stackedData))
        .attr('class', 'line')
        .enter().append('path')
        .attr('stroke', d => colorScale(d.key))
        // .style('mix-blend-mode', 'multiply')
        .attr('d', line);

    // Draw points
    /* viz.selectAll('circle')
        .data(stack(stackedData))
        .enter().append('circle')
        .attr('fill', d => colorScale(d.key))
        .attr('r', 3)
        .attr('cx', d => {
            x(d.key)
        })
        .attr('cy', d => {
            y(d[1])
        })
        .on('mouseover', d => {
            div.transition()
                .duration(200)
                .style('opacity', .9);
            div.html(d[1])
                .style('left', (d3.event.pageX - 25) + 'px')
                .style('top', (d3.event.pageY - 20) + 'px')
                .style('color', colorScale(d.key))
                .style('border', '1px solid ' + colorScale(d.key));
        })
        .on('mouseout', d => {
            div.transition()
                .duration(500)
                .style('opacity', 0);
        }) */;

    // Fill legends
    if(chartConfig.legend !== false) {
        legend(d3.values(data.map(d => d[filtro])).filter(unique), colorScale);
    }
}