import * as d3 from 'd3';
import route from 'riot-route';
import css from '../css/main.css';
import * as draw from './charts';
import * as config from './config';
import { snakeToCamel } from './helpers';

// Fill top menu
d3.select('.menu-entidade')
    .selectAll('li')
    .data(d3.keys(config))
    .enter().append('li')
    .attr('class', 'nav-item')
    .append('a')
    .attr('class', 'nav-link')
    .attr('href', d => '#' + d)
    .text(d => d.charAt(0).toUpperCase() + d.slice(1));

route((entidade, por, filtro) => {
    if(entidade && config[entidade]) {
        d3.select('.menu-filtro').text('');
        d3.select('.chart-title').text('');
        d3.select('.chart-container').text('');

        // Set top menu items state
        d3.selectAll('.menu-entidade .nav-link')
            .classed('active', (d, i, nodes) => {
                return nodes[i].getAttribute('href') === ('#' + entidade)
            });

        // Fill side menu
        var nomeEntidade = entidade.charAt(0).toUpperCase() + entidade.slice(1);
        d3.select('.menu-filtro')
            .selectAll('li')
            .data(Object.keys(config[entidade]))
            .enter().append('li')
            .attr('class', 'nav-item')
            .append('a')
            .attr('class', 'nav-link')
            .attr('href', d => '#' + entidade + '/por/' + d)
            .text(d => nomeEntidade + ' por ' + (config[entidade][d].text || d));

        if(por && filtro && config[entidade][filtro]) {
            d3.select('.home-text').remove();

            // Set side menu items state
            d3.selectAll('.menu-filtro .nav-link')
                .classed('active', (d, i, nodes) => {
                    return nodes[i].getAttribute('href') === ('#' + entidade + '/por/' + filtro)
                });

            // Create chart based on selected menu item
            createChart(entidade, filtro);
        }
    }
});

route.start(true);

// Create the chart
function createChart(entidade, filtro) {
    var chartConfig = config[entidade][filtro];
    var nomeEntidade = entidade.charAt(0).toUpperCase() + entidade.slice(1).toLowerCase();

    d3.select('.chart-title').text(nomeEntidade + ' por ' + (chartConfig.text || filtro));
    d3.select('.chart-container').text(''); // Clear chart area

    // Load data according to the route
    d3.json('api/' + entidade + '/por/' + filtro).then(function(data) {
        if(chartConfig.map) {
            data = data.map(chartConfig.map);
        }

        var chartDim = window.getComputedStyle(d3.select('.chart').node()),
            outerWidth = parseFloat(chartDim.width) - parseFloat(chartDim.paddingLeft) - parseFloat(chartDim.paddingRight),
            outerHeight = 500,
            hasLegend = chartConfig.legend !== false,
            margin = { top: 20, right: 20, bottom: hasLegend ? 60 : 30, left: 40 },
            width = outerWidth - margin.left - margin.right,
            height = outerHeight - margin.top - margin.bottom;

        // Create chart
        d3.select('.chart-container')
            .attr('width', outerWidth)
            .attr('height', outerHeight)
            .append('g')
            .attr('class', 'chart-content')
            .attr('width', width)
            .attr('height', height)
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        // Create legend
        if(hasLegend) {
            d3.select('.chart-container')
                .append('foreignObject')
                .attr('class', 'legend')
                .attr('width', width)
                .attr('height', margin.bottom / 3 * 2)
                .attr('x', margin.left)
                .attr('y', height + margin.top + margin.bottom / 3);
        }

        chartConfig.visualizations.forEach(visualization => {
            var chartFunction = draw[snakeToCamel(visualization)];
            if(chartFunction && typeof (chartFunction) === 'function') {
                chartFunction(entidade, filtro, data, chartConfig);
            }
        });
    });
}

function filterChart(data, entidade, filtro) {
    return function(d) {
        d3.selectAll('.btn-year').classed('active', _d => _d === d);

        var filterData = data.filter(_d => _d.ano === d);
        var width = d3.select('.chart-content').attr('width');
        var height = d3.select('.chart-content').attr('height');

        var transition = d3.transition()
            .duration(750);

        var x = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.1)
            .domain(filterData.map(d => d[filtro]));

        var y = d3.scaleLinear()
            .rangeRound([height, 0])
            .domain([0, d3.max(filterData, d => +d[entidade])]).nice();

        d3.select('.axis.x').call(d3.axisBottom(x));
        d3.select('.axis.y').call(d3.axisLeft(y));

        d3.select('.chart-content')
            .selectAll('circle')
            .data([])
            .exit().remove();

        var bars = d3.select('.chart-content')
            .selectAll('rect')
            .data(filterData, d => d[filtro]);

        bars.enter().append('rect')
            .attr('fill', 'steelblue')
            .attr('x', d => x(d[filtro]))
            .attr('y', height)
            .merge(bars)
            .transition(transition)
            .attr('width', x.bandwidth())
            .attr('x', d => x(d[filtro]))
            .attr('y', d => y(d[entidade]))
            .attr('height', d => height - y(d[entidade]))

        bars.exit()
            .transition(transition)
            .style('fill-opacity', 1e-6)
            .remove();
    }
}

css;