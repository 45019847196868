import * as d3 from 'd3';

export default {
    ano: {
        visualizations: ['line'],
        legend: false
    },
    campus: {
        visualizations: ['bar'],
        grouped: true,
        colorScale: d3.schemeDark2
    },
    grau_academico: {
        text: 'grau acadêmico',
        visualizations: ['bar'],
        grouped: true,
        colorScale: d3.schemeCategory10.slice(2)
    },
    turno: {
        visualizations: ['bar'],
        grouped: true,
        colorScale: d3.schemeCategory10.slice(8)
    },
    modalidade: {
        visualizations: ['bar'],
        grouped: true,
        colorScale: d3.schemeDark2.slice(2)
    }/* ,
    inicio_funcionamento: {
        text: 'início de funcionamento',
        visualizations: ['ranking'],
        legend: false
    },
    classificacao_ocde: {
        text: 'classificação OCDE',
        visualizations: ['tree'],
        legend: false
    },
    historico: {
        text: 'histórico de vagas, inscritos, ingressantes, concluintes e matriculados',
        visualizations: ['dynamic-bar']
    } */
};
