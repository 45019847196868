import * as d3 from 'd3';
import { legend, unique } from '../helpers';

export default function bar(entidade, filtro, data, chartConfig) {
    var chart = d3.select('.chart-content'),
        width = chart.attr('width'),
        height = chart.attr('height'),
        groupPadding = 10,
        minGroupHeight = 60,
        groupedData = d3.nest().key(d => d.ano).entries(data),
        nGroups = groupedData.length,
        groupHeight = (height - groupPadding * (nGroups - 1)) / nGroups;

    if(groupHeight < minGroupHeight) {
        groupHeight = minGroupHeight;
        height = groupHeight * nGroups + groupPadding * (nGroups - 1);
        chart.attr('height', height);
    }

    var x = d3.scaleBand()
        .rangeRound([0, width])
        .paddingInner(0.1)
        .domain(data.map(d => d[filtro]).filter(unique));

    var y = d3.scaleLinear()
        .domain([0, d3.max(data, d => +d[entidade])]).nice();

    // Draw X axis
    chart.append('g')
        .attr('class', 'axis x')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x));

    groupedData.forEach((group, index) => {

        y.rangeRound([groupHeight, 0]);

        // Draw y axis grid
        chart.append('g')
            .attr('class', 'grid')
            .attr('transform', 'translate(0,' + (groupHeight + groupPadding) * index + ')')
            .call(d3.axisLeft(y)
                .tickSize(-width)
                .tickFormat('')
                .ticks(3));

        // Draw Y axis
        chart.append('g')
            .attr('class', 'axis y')
            .attr('transform', 'translate(0,' + (groupHeight + groupPadding) * index + ')')
            .call(d3.axisLeft(y).ticks(3, 's'));

        // Draw bars
        chart.append('g')
            .attr('class', 'bars')
            .attr('transform', 'translate(0,' + (groupHeight + groupPadding) * index + ')')
            .selectAll('rect')
            .data(group.values)
            .enter().append('rect')
            .attr('x', d => x(d[filtro]))
            .attr('y', d => y(d[entidade]))
            .attr('width', x.bandwidth())
            .attr('height', d => groupHeight - y(d[entidade]))
            .attr('fill', 'steelblue');

        // Draw text labels
        /* chart.append('g')
            .attr('class', 'labels')
            .selectAll('text')
            .data(group.values)
            .enter().append('text')
            .text(d => d[entidade])
            .attr('class', d => 'label ' + (groupHeight - y(d[entidade]) < 20 ? 'out' : 'in'))
            .attr('x', d => x(d[filtro]) + x.bandwidth() / 2)
            .attr('y', d => groupHeight - y(d[entidade]) < 20 ? y(d[entidade]) - 5 : y(d[entidade]) + 15); */
    });

    // Fill legends
    if(chartConfig.legend !== false) {
        legend(d3.values(data.map(d => d[filtro])).filter(unique), colorScale);
    }
}