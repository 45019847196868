import * as d3 from 'd3';
import { legend, unique } from '../helpers';

export default function barDistribution(entidade, filtro, data, chartConfig) {
    var chart = d3.select('.chart-content'),
        width = chart.attr('width'),
        height = chart.attr('height');

    var x0 = d3.scaleBand()
        .rangeRound([0, width])
        .paddingInner(0.1)
        .domain(data.map(d => d.ano).filter(unique));

    var x1 = d3.scaleLinear()
        .rangeRound([1, x0.bandwidth()])
        .domain([0, d3.max(data, d => +d[entidade])]);

    var y = d3.scaleLinear()
        .range([height, 0])
        .domain(d3.extent(data, d => +d[filtro])).nice();

    var colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    // Draw X axis
    chart.append('g')
        .attr('class', 'axis x')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x0));

    // Draw Y axis grid
    chart.append('g')
        .attr('class', 'grid')
        .call(d3.axisLeft(y)
            .tickSize(-width)
            .tickFormat(''));

    // Draw Y axis
    chart.append('g')
        .attr('class', 'axis y')
        .call(d3.axisLeft(y).ticks(null, 's'));

    // Create tooltip
    var div = d3.select('body')
        .append('div')
        .attr('class', 'tooltip distribution')
        .style('opacity', 0);

    // Draw bars
    chart.append('g')
        .selectAll('rect')
        .data(data)
        .enter().append('rect')
        .attr('fill', d3.schemeCategory10[0])
        .attr('x', d => x0(d.ano) + (x0.bandwidth() / 2) - (x1(d[entidade]) / 2))
        .attr('y', d => y(d[filtro]) - 1)
        .attr('width', d => x1(d[entidade]))
        .attr('height', 3)
        .on('mouseover', d => {
            div.html(entidade + ': ' + d[entidade] + '<br>' + filtro + ': ' + d[filtro])
                .style('left', (d3.event.pageX - 40) + 'px')
                .style('top', (d3.event.pageY - 35) + 'px')
                .style('color', colorScale(d.key))
                .style('border', '1px solid ' + colorScale(d.key))
                .transition()
                .duration(200)
                .style('opacity', .9);
        })
        .on('mouseout', () => {
            div.transition()
                .duration(500)
                .style('opacity', 0);
        });

    // Fill legends
    if(chartConfig.legend !== false) {
        legend(d3.values(data.map(d => d[filtro])).filter(unique), colorScale);
    }
}