import * as d3 from 'd3';
import { legend, unique, capitalize } from '../helpers';

export default function bubble(entidade, filtro, data, chartConfig) {
    var chart = d3.select('.chart-content'),
        width = chart.attr('width'),
        height = chart.attr('height');

    var x0 = d3.scaleBand()
        .rangeRound([0, width])
        .paddingInner(0.1)
        .domain(data.map(d => d.ano).filter(unique));

    var x1 = d3.scaleLinear()
        .rangeRound([1, x0.bandwidth() / 5])
        .domain([0, d3.max(data, d => +d[entidade])]);

    var y = d3.scaleLinear()
        .rangeRound([height, 0])
        .domain(d3.extent(data, d => +d[filtro])).nice();

    var colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    // Draw y axis grid
    chart.append('g')
        .attr('class', 'grid')
        .call(d3.axisLeft(y)
            .tickSize(-width)
            .tickFormat(''));

    // Draw X axis
    chart.append('g')
        .attr('class', 'axis x')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x0));

    // Insert Y axis name
    chart.append('text')
        .attr('class', 'axis-label')
        .attr('transform', 'rotate(270)')
        .attr('x', -(height / 2))
        .attr('y', -30)
        .text(capitalize(filtro));

    // Draw Y axis
    chart.append('g')
        .attr('class', 'axis y')
        .call(d3.axisLeft(y).ticks(null, 's'));

    // Draw bubbles
    chart.append('g')
        .selectAll('circle')
        .data(data)
        .enter().append('circle')
        .attr('fill', d3.schemeCategory10[0])
        .attr('cx', d => x0(d.ano) + (x0.bandwidth() / 2))
        .attr('cy', d => y(d[filtro]))
        .attr('r', d => x1(d[entidade]));

    // Fill legends
    if(chartConfig.legend !== false) {
        legend(d3.values(data.map(d => d[filtro])).filter(unique), colorScale);
    }
}